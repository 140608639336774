/* eslint-disable max-len */
'use client';
import { useState } from 'react';
import Link from 'next/link';
import { SignedIn, SignedOut, UserButton } from '@clerk/nextjs';
import { usePathname, useRouter } from 'next/navigation';
import { Button } from "@heroui/react";
import Logo from '../svgs/Logo';
import AnnouncementBar from '../ui/announcement-bar';
import { cn } from "@heroui/theme";
import * as React from 'react';
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle,
} from '../ui/navigation-menu';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordian';
import { BarChart, Sparkles, User } from 'lucide-react';
import { Shield, AlertCircle, Info, Lock, DollarSign, Users, Newspaper, List } from 'lucide-react';
import { title } from 'process';

interface ListItemProps extends React.ComponentPropsWithoutRef<'a'> {
    className?: string;
    title: string;
    icon: React.ComponentType<{ className?: string }>;
}

const ListItem = React.forwardRef<React.ElementRef<'a'>, ListItemProps>(({ className, title, icon: Icon, children, ...props }, ref) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <a
                    ref={ref}
                    className={cn(
                        'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-primary-100 focus:bg-primary focus:text-primary-foreground',
                        className
                    )}
                    {...props}
                >
                    <div className="flex items-center space-x-2">
                        <Icon className="h-4 w-4 flex-shrink-0" />
                        <div className="text-sm font-medium leading-none">{title}</div>
                    </div>
                    {children && <p className="text-muted-foreground line-clamp-2 text-sm leading-snug">{children}</p>}
                </a>
            </NavigationMenuLink>
        </li>
    );
});
ListItem.displayName = 'ListItem';

const scamInsights = [
    {
        title: 'Introduction to Scams',
        href: '/scam-insights/introduction-to-scams',
        icon: Info,
    },
    {
        title: 'Introduction to Scam Prevention',
        href: '/scam-insights/introduction-to-scam-prevention',
        icon: Lock,
    },
    {
        title: 'Highlights of Scam Statistics',
        href: '/scam-insights/highlights-of-scam-statistics',
        icon: BarChart,
    },
    {
        title: 'Types of Scams',
        href: '/scam-types',
        icon: Shield,
    },
    {
        title: 'Other Scam Articles',
        href: '/scam-prevention',
        icon: AlertCircle,
    },
];

const stayProtected = [
    {
        title: 'Online Safety Tips',
        href: '/stay-protected/online-safety-tips',
        icon: Lock,
    },
    {
        title: 'Common Tactics to Gain Your Personal Information',
        href: '/stay-protected/common-tactics-to-gain-your-personal-information',
        icon: Info,
    },
    // {
    //     title: 'Financial Safety Tips',
    //     href: '/financial-safety-tips',
    //     icon: DollarSign,
    // },
    // {
    //     title: 'Protecting Kids Online',
    //     href: '/protecting-kids-online',
    //     icon: Users,
    // },
];

const researchResources = [
    {
        title: 'Featured Posts',
        href: '/featured-posts',
        icon: Sparkles,
    },
    {
        title: 'News & Alerts',
        href: '/resources/news-and-alerts',
        icon: Newspaper,
    },
    {
        title: 'Scam.SG Watchlist',
        href: '/watchlist',
        icon: List,
    },
    // {
    //     title: 'Scam Bulletin',
    //     href: '/resources/scam-bulletin',
    //     icon: AlertCircle,
    // },
    // {
    //     title: 'Scam Statistics',
    //     href: '/resources/scam-statistics',
    //     icon: BarChart,
    // },
];

const PROMO_REF = 'pre-launch-2024';

const Header = ({ ...props }: { className: string }) => {
    const router = useRouter();
    const [showMenu, setShowMenu] = useState(false);
    const pathname = usePathname();
    const isBusinessPath = pathname ? pathname.includes('/business') && !pathname.includes('/companies') : false;
    const excludeAnnouncementBar = pathname === '/business/pre-launch-offer';

    const toggleMenu = () => {
        if (window.innerWidth < 1024) {
            setShowMenu(!showMenu);
        } else {
            setShowMenu(false);
        }
    };

    return (
        (<header className={cn(`sticky top-0 z-50 duration-300  border-b border-gray-200 bg-white ${props.className}`)}>
            {isBusinessPath && !excludeAnnouncementBar && <AnnouncementBar animate />}
            <div className="container">
                <nav className="flex items-center justify-between py-4 lg:justify-start">
                    <Link rel="canonical" prefetch={true} href="/" className="w-40 items-center justify-start">
                        <Logo />
                    </Link>

                    {isBusinessPath ? (
                        // Business path navigation
                        (<div className="hidden w-full justify-end lg:flex">
                            <NavigationMenu>
                                <NavigationMenuList>
                                    {pathname.includes("/business/pre-launch-offer") ? (
                                        <NavigationMenuItem>
                                            <Link href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}/sign-up?ref=${PROMO_REF}`} legacyBehavior passHref>
                                                <Button size="sm" color="primary" className="font-bold text-white">
                                                    Get Verified Now
                                                </Button>
                                            </Link>
                                        </NavigationMenuItem>
                                    ) : (
                                        <>
                                            <NavigationMenuItem>
                                                <Link href="/business" legacyBehavior passHref>
                                                    <NavigationMenuLink className={navigationMenuTriggerStyle()}>Home</NavigationMenuLink>
                                                </Link>
                                            </NavigationMenuItem>
                                            <NavigationMenuItem>
                                                <Link href="/business/pricing" legacyBehavior passHref>
                                                    <NavigationMenuLink className={navigationMenuTriggerStyle()}>Pricing</NavigationMenuLink>
                                                </Link>
                                            </NavigationMenuItem>
                                            <NavigationMenuItem>
                                                <Link href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}`} legacyBehavior passHref>
                                                    <NavigationMenuLink className={navigationMenuTriggerStyle()}>Login</NavigationMenuLink>
                                                </Link>
                                            </NavigationMenuItem>
                                            <NavigationMenuItem>
                                                <Link href="/" legacyBehavior passHref>
                                                    <Button size="sm" color="primary" className="font-bold text-white">
                                                        For Consumers
                                                    </Button>
                                                </Link>
                                            </NavigationMenuItem>
                                        </>
                                    )}
                                </NavigationMenuList>
                            </NavigationMenu>
                        </div>)
                    ) : (
                        // Consumer path navigation
                        (<div className="hidden w-full justify-end lg:flex">
                            <NavigationMenu className="top-0 flex flex-col place-content-start content-start">
                                <NavigationMenuList className="relative">
                                    <NavigationMenuItem>
                                        <Link href="/companies" legacyBehavior passHref>
                                            <NavigationMenuLink className={navigationMenuTriggerStyle()}>Search Company</NavigationMenuLink>
                                        </Link>
                                    </NavigationMenuItem>
                                    <NavigationMenuItem>
                                        <Link href="/stay-protected/what-to-do-if-you-have-been-scammed" legacyBehavior passHref>
                                            <NavigationMenuLink className={navigationMenuTriggerStyle()}>Report a Scam</NavigationMenuLink>
                                        </Link>
                                    </NavigationMenuItem>
                                    <NavigationMenuItem>
                                        <Link href="/trustscore" legacyBehavior passHref>
                                            <NavigationMenuLink className={navigationMenuTriggerStyle()}>TrustScore</NavigationMenuLink>
                                        </Link>
                                    </NavigationMenuItem>
                                    <NavigationMenuItem>
                                        <NavigationMenuTrigger>Scam Insights & Protection</NavigationMenuTrigger>
                                        <NavigationMenuContent className="p-4">
                                            <ul className="flex min-w-[500px] flex-col gap-4">
                                                <li>
                                                    <Link href="/scam-insights" className="mb-2 text-sm font-semibold hover:underline">
                                                        Scam Insights
                                                    </Link>
                                                    {/* <NavigationMenu>
                                                        <NavigationMenuList>
                                                            {scamInsights.map((item) => (
                                                                <NavigationMenuItem key={item.title}>
                                                                    {item.children ? (
                                                                        <>
                                                                            <NavigationMenuTrigger>
                                                                                <div className="flex items-center space-x-2">
                                                                                    <item.icon className="h-4 w-4" />
                                                                                    <span className="text-sm">{item.title}</span>
                                                                                </div>
                                                                            </NavigationMenuTrigger>
                                                                            <NavigationMenuContent>
                                                                                <ul className="p-2 w-[200px]">
                                                                                    {item.children.map((child) => (
                                                                                        <ListItem key={child.title} title={child.title} href={child.href} icon={child.icon} />
                                                                                    ))}
                                                                                </ul>
                                                                            </NavigationMenuContent>
                                                                        </>
                                                                    ) : (
                                                                        <Link href={item.href} className={navigationMenuTriggerStyle()}>
                                                                            <div className="flex items-center space-x-2">
                                                                                <item.icon className="h-4 w-4" />
                                                                                <span className="text-sm">{item.title}</span>
                                                                            </div>
                                                                        </Link>
                                                                    )}
                                                                </NavigationMenuItem>
                                                            ))}
                                                        </NavigationMenuList>
                                                    </NavigationMenu> */}
                                                    {scamInsights.map((item) => (
                                                        <ListItem key={item.title} title={item.title} href={item.href} icon={item.icon} />
                                                    ))}
                                                </li>
                                                <li className="border-t pt-4">
                                                    <Link href="/stay-protected" className="mb-2 text-sm font-semibold">
                                                        Stay Protected
                                                    </Link>
                                                    {stayProtected.map((item) => (
                                                        <ListItem key={item.title} title={item.title} href={item.href} icon={item.icon} />
                                                    ))}
                                                </li>
                                            </ul>
                                        </NavigationMenuContent>
                                    </NavigationMenuItem>
                                    <NavigationMenuItem>
                                        <NavigationMenuTrigger>Research & Resources</NavigationMenuTrigger>
                                        <NavigationMenuContent>
                                            <ul className="grid w-[400px] gap-3 p-4 md:w-[700px] md:grid-cols-2">
                                                {researchResources.map((item) => (
                                                    <ListItem key={item.title} title={item.title} href={item.href} icon={item.icon}>
                                                        {/* {item.title} */}
                                                    </ListItem>
                                                ))}
                                            </ul>
                                        </NavigationMenuContent>
                                    </NavigationMenuItem>

                                    {/* Auth buttons */}
                                    <NavigationMenuItem>
                                        <SignedOut>
                                            <Link href="/login" legacyBehavior passHref>
                                                <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                                                    <User />
                                                </NavigationMenuLink>
                                            </Link>
                                        </SignedOut>
                                        <SignedIn>
                                            <UserButton />
                                        </SignedIn>
                                    </NavigationMenuItem>

                                    {/* Add For Business button */}
                                    <NavigationMenuItem>
                                        <Link href="/business" legacyBehavior passHref>
                                            {/* <Button size="sm" color="primary" className='text-white font-bold'>
                                                For Business
                                            </Button> */}
                                            <NavigationMenuLink className={`${navigationMenuTriggerStyle()} bg-primary font-bold text-white`}>
                                                For Business
                                            </NavigationMenuLink>
                                        </Link>
                                    </NavigationMenuItem>
                                </NavigationMenuList>
                            </NavigationMenu>
                        </div>)
                    )}

                    {/* Mobile menu button and panel */}
                    <div className="lg:hidden">
                        <button type="button" className="flex h-10 w-10 items-center justify-center rounded-full bg-primary" onClick={() => toggleMenu()}>
                            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-white">
                                <path
                                    d="M2 15H11C11.552 15 12 15.447 12 16C12 16.553 11.552 17 11 17H2C1.448 17 1 16.553 1 16C1 15.447 1.448 15 2 15Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M2 8H20C20.552 8 21 8.447 21 9C21 9.553 20.552 10 20 10H2C1.448 10 1 9.553 1 9C1 8.447 1.448 8 2 8Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M21 2C21 1.447 20.552 1 20 1H7C6.448 1 6 1.447 6 2C6 2.553 6.448 3 7 3H20C20.552 3 21 2.553 21 2Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                    </div>

                    {/* Mobile menu overlay and panel */}
                    {showMenu && (
                        <>
                            <div className="fixed inset-0 z-[51] bg-black/60 lg:hidden" onClick={() => toggleMenu()} />
                            <div className="fixed inset-0 z-[52] transform overflow-y-auto bg-white p-4 transition-transform duration-300 lg:hidden">
                                <div className="mb-4 flex justify-end border-b border-gray-200 pb-4">
                                    <button onClick={() => toggleMenu()} type="button" className="p-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-6 w-6"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>

                                {/* Mobile menu content */}
                                {isBusinessPath ? (
                                    pathname.includes("/business/pre-launch-offer") ? (
                                        <div className="flex justify-center">
                                            <Link href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}/sign-up?ref=${PROMO_REF}`} className="block px-4 py-2 bg-primary text-white rounded-md" onClick={() => toggleMenu()}>
                                                Get Verified Now
                                            </Link>
                                        </div>
                                    ) : (
                                        // Business mobile menu
                                        (<ul className="space-y-4">
                                            <li>
                                                <Link
                                                    href="/business"
                                                    className="block px-2 py-1 hover:bg-gray-100 active:bg-gray-200"
                                                    onClick={() => toggleMenu()}
                                                >
                                                    Home
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    href="/business/pricing"
                                                    className="block px-2 py-1 hover:bg-gray-100 active:bg-gray-200"
                                                    onClick={() => toggleMenu()}
                                                >
                                                    Pricing
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}`}
                                                    className="block px-2 py-1 hover:bg-gray-100 active:bg-gray-200"
                                                    onClick={() => toggleMenu()}
                                                >
                                                    Login
                                                </Link>
                                            </li>
                                            <li>
                                                <Link href="/" className="block px-2 py-1 hover:bg-gray-100 active:bg-gray-200" onClick={() => toggleMenu()}>
                                                    For Consumers
                                                </Link>
                                            </li>
                                        </ul>)
                                    )
                                ) : (
                                    // Consumer mobile menu
                                    (<div className="space-y-4">
                                        <Link href="/companies" className="block px-2 py-1 hover:bg-gray-100 active:bg-gray-200" onClick={() => toggleMenu()}>
                                            Search Company
                                        </Link>
                                        <Link
                                            href="/what-to-do-if-im-scammed"
                                            className="block px-2 py-1 hover:bg-gray-100 active:bg-gray-200"
                                            onClick={() => toggleMenu()}
                                        >
                                            Report a Scam
                                        </Link>
                                        <Link href="/trustscore" className="block px-2 py-1 hover:bg-gray-100 active:bg-gray-200" onClick={() => toggleMenu()}>
                                            TrustScore
                                        </Link>
                                        <Accordion type="single" collapsible className="w-full">
                                            <AccordionItem value="scam-reports">
                                                <AccordionTrigger>Scam Insights & Protection</AccordionTrigger>
                                                <AccordionContent>
                                                    <div className="space-y-4">
                                                        <div>
                                                            <p className="mb-2 text-sm font-semibold">Scam Insights</p>
                                                            <div className="space-y-2">
                                                                {scamInsights.map((item) => (
                                                                    <React.Fragment key={item.title}>
                                                                        <Link
                                                                            href={item.href}
                                                                            className="block py-1 text-sm hover:bg-gray-100 hover:text-primary active:bg-gray-200"
                                                                            onClick={() => toggleMenu()}
                                                                        >
                                                                            <item.icon className="mr-2 inline-block h-4 w-4" />
                                                                            {item.title}
                                                                        </Link>
                                                                        {/* {item.children &&
                                                                            item.children.map((child) => (
                                                                                <Link
                                                                                    key={child.title}
                                                                                    href={child.href}
                                                                                    className="block py-1 pl-6 text-sm hover:bg-gray-100 hover:text-primary active:bg-gray-200"
                                                                                    onClick={() => toggleMenu()}
                                                                                >
                                                                                    <child.icon className="mr-2 inline-block h-4 w-4" />
                                                                                    {child.title}
                                                                                </Link>
                                                                            ))} */}
                                                                    </React.Fragment>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {/* <div>
                                                            <p className="mb-2 text-sm font-medium">Stay Protected</p>
                                                            <div className="space-y-2">
                                                                {stayProtected.map((item) => (
                                                                    <Link
                                                                        key={item.title}
                                                                        href={item.href}
                                                                        className="block py-1 text-sm hover:bg-gray-100 hover:text-primary active:bg-gray-200"
                                                                        onClick={() => toggleMenu()}
                                                                    >
                                                                        <item.icon className="mr-2 inline-block h-4 w-4" />
                                                                        {item.title}
                                                                    </Link>
                                                                ))}
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </AccordionContent>
                                            </AccordionItem>
                                            <AccordionItem value="research">
                                                <AccordionTrigger>Research & Resources</AccordionTrigger>
                                                <AccordionContent>
                                                    <div className="space-y-2">
                                                        {researchResources.map((item) => (
                                                            <Link
                                                                key={item.title}
                                                                href={item.href}
                                                                className="block py-1 text-sm hover:bg-gray-100 hover:text-primary active:bg-gray-200"
                                                                onClick={() => toggleMenu()}
                                                            >
                                                                <item.icon className="mr-2 inline-block h-4 w-4" />
                                                                {item.title}
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </AccordionContent>
                                            </AccordionItem>
                                        </Accordion>
                                        <div className="pt-2">
                                            <SignedOut>
                                                <Link href="/login" className="block p-2 hover:bg-gray-100 active:bg-gray-200">
                                                    Login
                                                </Link>
                                            </SignedOut>
                                            <SignedIn>
                                                <UserButton />
                                            </SignedIn>
                                        </div>
                                        <Link
                                            href="/business"
                                            className="block rounded-md bg-primary p-2 font-bold text-white hover:bg-primary-400 active:bg-primary-800"
                                            onClick={() => toggleMenu()}
                                        >
                                            For Business
                                        </Link>
                                    </div>)
                                )}
                            </div>
                        </>
                    )}
                </nav>
            </div>
        </header>)
    );
};

export default Header;
